.text_overflow{
    display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
text-align: justify;
}

.jodit-status-bar-link {
    display: none !important;
    }