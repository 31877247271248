label + .MuiInput-formControl{
    margin-top: 0;
}

.MuiFormControl-marginNormal {
    margin-top: 0!important;
    margin-bottom: 0!important;
}

.ver_regras{
    color:rgb(98, 110, 212) !important;
    cursor:pointer !important;
    font-weight: bold;
}

.ver_regras:hover{
    color:rgb(98, 110, 150) !important;

}


.lines {
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    color:black;
    line-height: 38px;
    border: 0;
    background-image: linear-gradient(transparent, transparent calc(38px - 1px), lightgrey 0px);
    background-size: 100% 38px;
    /* height: 1152px; */
    resize: none;
    text-align: justify;
    word-break: normal;
    white-space: pre-wrap;
    overflow: hidden;
    padding-top: 10px;
  }
  
  .lines_finish {
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    color:black;
    line-height: 38px;
    border: 0;
    /* background-image: linear-gradient(transparent, transparent calc(38px - 1px), lightgrey 0px);
    background-size: 100% 38px; */
    /* height: 1152px; */
    resize: none;
    text-align: justify;
    word-break: normal;
    white-space: pre-wrap;
    overflow: hidden;
    padding-top: 10px;
  }
  
  .lines:focus {
    outline: none;
  }

  .count_line{
    line-height: 37px;
    margin:0;
    /* padding:0; */
    font-size: 16px;
    font-family: 'Courier New', Courier, monospace;
    /* padding: 0.011rem; */
  }
  
  .container_scroll {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
  }